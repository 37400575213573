import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

interface JwtPayload {
  email: string;
  username: string;
  userId: string;
  country: string;
  city: string;
  isVerified: number;
}

const Contact: React.FC = () => {
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [email, setEmail] = useState<string | null>(null);
  const [username, setUsername] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [verified, setVerified] = useState<string>("account is not verified");
  const [senderEmail, setSenderEmail] = useState<string>("");
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token) as JwtPayload;
        setEmail(decodedToken.email);
        setUsername(decodedToken.username);
        setUserId(decodedToken.userId);
        setCountry(decodedToken.country);
        setCity(decodedToken.city);
        const isVerified = localStorage.getItem("isVerified");
        if (isVerified === "1") {
          setVerified("account is verified");
        } else {
          setVerified("account is not verified");
        }
      } catch (error) {
        console.error("Invalid token:", error);
      }
    }
  }, [token]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!token && !senderEmail) {
      alert("Please enter your email address.");
      return;
    }

    const infoText = token
      ? `Sender - user ID: ${userId}, username: ${username}, email: ${email}, location: ${country} - ${city}, verified: ${verified}`
      : `Sender (no user-account or not-logged-in) - email: ${senderEmail}`;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/send-supportemail`,
        {
          title,
          message: `${message}`,
          email: token ? email : senderEmail,
          infoText,
        },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : undefined,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        console.log("Email sent successfully");
        alert("Your message has been sent!");
        navigate("/");
      } else {
        alert("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message. Please try again.");
    }
  };

  return (
    <div className="container p-4">
      <Helmet>
        <title>Contact - Pirpana</title>
        <meta
          name="description"
          content="Get in touch with us at Pirpana. We're here to help with any questions or feedback you may have."
        />
        <meta name="keywords" content="contact us, customer support, Pirpana" />
      </Helmet>
      <h1 className="title mb-3">Contact Us</h1>
      <br />
      <p>
        You can send an email to our support team for any questions, feedback,
        or issues you may have. We're here to help!
      </p>
      <p>
        If you're experiencing any technical difficulties or have concerns about
        your account, don't hesitate to reach out. Our team will get back to you
        as soon as possible.
      </p>
      <p>Thank you for being part of our community!</p>
      <br />

      <form onSubmit={handleSubmit}>
        {!token && (
          <div style={{ marginBottom: "10px" }}>
            <label htmlFor="userEmail">Your Email:</label>
            <input
              type="email"
              id="userEmail"
              value={senderEmail}
              onChange={(e) => setSenderEmail(e.target.value)}
              required
              style={{
                width: "100%",
                padding: "8px",
                boxSizing: "border-box",
                marginTop: "5px",
              }}
            />
          </div>
        )}
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            maxLength={36}
            required
            style={{
              width: "100%",
              padding: "8px",
              boxSizing: "border-box",
              marginTop: "5px",
            }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="message">Your Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            style={{
              width: "100%",
              padding: "8px",
              boxSizing: "border-box",
              marginTop: "5px",
              height: "150px",
            }}
          />
        </div>
        <button
          type="submit"
          className="btn-submit w-50"
          style={{
            backgroundColor: "rgb(13, 119, 108)",
            borderRadius: "12px",
            cursor: "pointer",
          }}
        >
          Send
        </button>
      </form>
      <div className="privacy-info">
        <p>
          By submitting this form, you agree to our
          <a
            href="/privacy-policy"
            target="_blank"
            style={{ color: "rgb(13, 119, 108)", textDecoration: "none" }}
          >
            {" "}
            privacy policy
          </a>
        </p>
      </div>
    </div>
  );
};

export default Contact;
