import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import chattingfigures from "./images/chattingfigures.jpg";
import closeButton from "./images/closebutton.png";
import messagesnew from "./images/messagesnew.png";
import locationpin from "./images/locationpin.png";
import newmessagebutton from "./images/newmessagebutton.png";
import chattingpeople from "./images/chattingpeople.jpg";
import send from "./images/send.png";
import mailN from "./images/mailN.png";

interface Chat {
  idchats: number;
  items_iditems: string;
  title: string;
  messages: {
    content: string;
    timestamp: string;
    username: string;
    sender_users_idusers: string;
    receiver_users_idusers: string;
  }[];
}

const Messages: React.FC = () => {
  interface ChatItem {
    iditems: number;
    itemtitle: string;
    itemdescription: string;
    price: number;
    subcategories_idsubcategories: number;
    subcategoryname: string;
    holder_idusers: string;
    holder_name: string;
    itemcondition: string;
    sellbuy: string;
    closed: string;
    location_info: string;
    dateadded: string;
    lastedited: string;
    imagePaths: string[];
  }

  const [chatItem, setChatItem] = useState<ChatItem | null>(null);

  const [MyUserId, setMyUserId] = useState<string | null>(null);
  const [MyUserName, setMyUserName] = useState<string | null>(null);
  const [receiverId, setReceiverId] = useState<string | null>(null);
  const [ItemHolderUserId, setItemHolderUserId] = useState<string | null>(null);
  const [ItemHolderUserName, setItemHolderUserName] = useState<string | null>(
    null
  );
  const [chats, setChats] = useState<Chat[]>([]);
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [chatId, setChatId] = useState<number | null>(null);
  const [itemId, setItemId] = useState<string | null>(null);
  const [chatTitle, setChatTitle] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [messages, setMessages] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [inputMessage, setInputMessage] = useState("");
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [notifIds, setNotifIds] = useState<number[]>([]);
  const [isTypeMessageVisible, setTypeMessageIsVisible] = useState(false);
  const [warningBoxVisible, setWarningBoxVisible] = useState(true);

  const navigate = useNavigate();

  const fetchUnreadChats = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    if (!MyUserId) {
      return;
    }

    try {
      const fetchUnreadChatIds = async () => {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER}/api/unread-chats/${MyUserId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          const unreadChatIds = response.data.unreadChats;
          localStorage.setItem("notif", JSON.stringify(unreadChatIds));
          const notif = localStorage.getItem("notif");
          if (notif) {
            setNotifIds(JSON.parse(notif));
          }
        } else {
          console.error("Failed to fetch unread chat IDs");
        }
      };

      // Call the function to fetch unread chat IDs
      await fetchUnreadChatIds();
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Error");
    }
  };

  useEffect(() => {
    fetchUnreadChats(); // Fetch unread chats immediately on mount

    const intervalId = setInterval(() => {
      fetchUnreadChats();
    }, 10 * 60 * 1000); // 10 minutes in milliseconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [MyUserId, setChatId]);

  // useEffect(() => {
  //   const fetchNotifications = () => {
  //     const notif = localStorage.getItem("notif");
  //     if (notif) {
  //       setNotifIds(JSON.parse(notif));
  //     }
  //   };
  //   fetchNotifications();
  //   const intervalId = setInterval(fetchNotifications, 5000); // 5000 ms = 5 sekuntia
  //   return () => clearInterval(intervalId);
  // }, [navigate]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const generateUniqueChatId = (
    MyUserId: string | null,
    ItemHolderUserId: string | null,
    itemId: string | null
  ) => {
    return Number(`${MyUserId}${ItemHolderUserId}${itemId}`);
  };

  const formatDate = (dateString: string) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedChat?.messages]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const MYidusers = localStorage.getItem("userId");

    if (MYidusers) {
      setMyUserId(MYidusers);
    }

    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const decodedToken: any = jwtDecode(token);
      setMyUserName(decodedToken.username);
    } catch (error) {
      console.error("Failed to decode token:", error);
    }

    const selectedItem = JSON.parse(
      localStorage.getItem("selectedItem") || "null"
    );
    if (selectedItem) {
      setSelectedItem(selectedItem);
      setItemHolderUserId(selectedItem.users_idusers);
      setChatTitle(selectedItem.itemtitle);
      setItemId(selectedItem.iditems);
      setItemHolderUserName(selectedItem.username);

      localStorage.removeItem("selectedItem");

      // TULOSTIMIA JA TARKASTUKSIA /////////////////////////////
      console.log("Selected item:", selectedItem);
      console.log("Selected item Title:", selectedItem.itemtitle);
    }
  }, [navigate]);

  const lastFetchTimeRef = useRef<number | null>(null);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchChatsAndStartChat = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }

      try {
        if (MyUserId && ItemHolderUserId && itemId && chatTitle) {
          const newChatId = generateUniqueChatId(
            MyUserId,
            ItemHolderUserId,
            itemId
          );

          if (!newChatId) {
            console.error("Failed to generate a new chat ID");
            return;
          }

          if (selectedChat?.idchats) {
            return;
          }

          // Check if new chat should be started and fetch chats in one request
          const startMessage =
            selectedItem.itemtitle +
            " (id" +
            selectedItem.iditems +
            ")   " +
            selectedItem.price +
            " PHP" +
            "\n - " +
            selectedItem.itemdescription +
            "\n - Location: " +
            selectedItem.location_info;

          const response = await axios.post(
            `${process.env.REACT_APP_SERVER}/api/check-and-add-chat`,
            {
              idchats: newChatId,
              items_iditems: itemId,
              title: `${getFormattedDateTime()} ${MyUserName} | ${ItemHolderUserName} "${chatTitle}"`,
              myIdusers: MyUserId,
              holderIdusers: ItemHolderUserId,
              startMessage: startMessage,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.data.success) {
            setChats(response.data.chats);
            const existingChat = response.data.chat; // Check if the chat already existed

            if (existingChat) {
              const chatExists = response.data.chats.some(
                (chat: Chat) => chat.idchats === existingChat.idchats
              );

              if (chatExists) {
                handleChatClick(existingChat);
              } else {
                alert("This conversation is closed");
              }
            } else {
              alert("Chat added");
            }
          } else {
            alert("Failed to start or fetch chats");
          }
        } else {
          // Fetch existing chats only
          const now = Date.now();

          // Debounce: Allow fetching only if last fetch was more than 2 seconds ago
          if (
            !lastFetchTimeRef.current ||
            now - lastFetchTimeRef.current > 1000
          ) {
            lastFetchTimeRef.current = now;

            // Fetch existing chats only
            const response = await axios.get(
              `${process.env.REACT_APP_SERVER}/api/pull-chats`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.data.success) {
              setChats(response.data.chats);
            } else {
              alert("Failed to fetch chats");
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Error");
      }
    };

    fetchChatsAndStartChat();

    // Cleanup timeout on unmount
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [MyUserId, ItemHolderUserId, itemId, chatTitle, navigate]);

  const getFormattedDateTime = (): string => {
    const now = new Date();
    const day = now.toLocaleDateString("en-PH", { day: "2-digit" });
    const month = now.toLocaleDateString("en-PH", { month: "short" });
    const year = now.toLocaleDateString("en-PH", { year: "numeric" });
    // const time = now.toLocaleTimeString("en-PH", {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });

    return `${day}-${month}-${year}`;
  };

  const closeChatButton = () => {
    setSelectedChat(null);
    setChatId(null);
    setTypeMessageIsVisible(false);
    fetchUnreadChats();
  };

  const handleChatClick = async (chat: Chat) => {
    setSelectedChat(chat);
    setChatId(chat.idchats);
    setChatTitle(chat.title);
    console.log(chat);

    const myId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    if (token && chat.items_iditems) {
      axios
        .get(`/api/getitem/${chat.items_iditems}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const {
            iditems = chat.items_iditems,
            itemtitle,
            itemdescription,
            price,
            subcategories_idsubcategories,
            subcategoryname,
            holder_idusers,
            holder_name,
            itemcondition,
            sellbuy,
            closed,
            dateadded,
            lastedited,
            location_info,
          } = res.data;

          setChatItem({
            iditems,
            itemtitle,
            itemdescription,
            price,
            subcategories_idsubcategories,
            subcategoryname,
            holder_idusers,
            holder_name,
            itemcondition,
            sellbuy,
            closed,
            dateadded,
            lastedited,
            location_info,
            imagePaths: [],
          });
          setItemHolderUserId(holder_idusers);
          setItemHolderUserName(holder_name);
        })
        .catch((err) => {
          console.error("Error fetching item details:", err);
        });

      axios
        .get(`/api/itemimages/${chat.items_iditems}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setChatItem((prevChatItem) => {
            if (prevChatItem) {
              return {
                ...prevChatItem,
                imagePaths: res.data,
              };
            }
            return prevChatItem;
          });
        })
        .catch((err) => {
          console.error("Error fetching item images:", err);
        });
    }

    try {
      if (notifIds && notifIds.length > 0 && notifIds.includes(chat.idchats)) {
        try {
          await axios.post(
            `${process.env.REACT_APP_SERVER}/api/messages-read`,
            { chatId: chat.idchats, MyUserId: MyUserId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          console.log("Messages marked as read");
        } catch (error) {
          console.error("Error marking messages as read:", error);
          alert("Failed to mark messages as read");
        }
      }

      // Hae local storage -arvo ja päivitä notifIds
      const notifString = localStorage.getItem("notif");
      const storedNotifIds: number[] = notifString
        ? JSON.parse(notifString)
        : [];

      // Suodata pois valittu chat.idchats
      const updatedNotifIds = storedNotifIds.filter(
        (id: number) => id !== chat.idchats
      );

      // Päivitä localStorage päivitetyn listan kanssa
      localStorage.setItem("notif", JSON.stringify(updatedNotifIds));

      // Päivitä komponentin tila
      setNotifIds(updatedNotifIds);

      console.log(chat.idchats); // Tulostaa poistettavan chatin id:n
      console.log(updatedNotifIds); // Tulostaa päivitetyn notifIds-listan

      // Toinen try lohko: Hae viestit
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER}/api/get-messages/${chat.idchats}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          setSelectedChat({
            ...chat,
            messages: response.data.messages as {
              content: string;
              timestamp: string;
              username: string;
              sender_users_idusers: string;
              receiver_users_idusers: string;
            }[],
          });
        } else {
          alert("Failed to fetch messages");
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
        alert("Error fetching messages");
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      alert("Unexpected error occurred");
    }
  };

  useEffect(() => {
    const updateReceiverId = () => {
      const myId = localStorage.getItem("userId");

      if (selectedChat) {
        const senderId = selectedChat.messages[0]?.sender_users_idusers;
        const receiverId = selectedChat.messages[0]?.receiver_users_idusers;
        if (!senderId || !receiverId) {
          alert("Sender ID or Receiver ID is missing.");
          return;
        }
        const actualReceiverId = senderId == myId ? receiverId : senderId;
        setReceiverId(actualReceiverId);
      }
    };
    updateReceiverId();
  }, [isTypeMessageVisible]);

  const handleSendMessage = (chat: Chat) => {
    sendMessage(chat);
    setInputMessage("");
    alert("Message ✉️ is on the way 📫");
    sendMessageNotification(chat);
    setTypeMessageIsVisible(false);
  };

  const handleMessageChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setInputMessage(event.target.value);
  };

  const sendMessageNotification = async (chat: Chat) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/send-message-notification`,
        {
          // chatId: chat.idchats,
          chatTitle: chat.title,
          receiverId: receiverId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        console.log(
          "Notification email sent successfully, considering user settings"
        );
      } else {
        alert("Failed to send notification.");
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      alert("Error sending notification.");
    }
  };

  const sendMessage = async (chat: Chat) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      if (selectedChat && MyUserId && receiverId && inputMessage) {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER}/api/send-message`,
          {
            idchats: selectedChat.idchats,
            myIdusers: MyUserId,
            receiverId: receiverId,
            inputMessage: inputMessage,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_SERVER}/api/get-messages/${chat.idchats}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.data.success) {
              setSelectedChat({
                ...chat,
                messages: response.data.messages as {
                  content: string;
                  timestamp: string;
                  username: string;
                  sender_users_idusers: string;
                  receiver_users_idusers: string;
                }[],
              });
            } else {
              alert("Failed to fetch messages");
            }
          } catch (error) {
            console.error("Error fetching messages:", error);
            alert("Error");
          }
        } else {
          alert("Failed to send message");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Error");
    }
  };

  const handleCloseWarningBox = () => {
    setWarningBoxVisible(false);
  };

  const handleBlockConversation = async (chat: Chat) => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }

    const confirmBlock = window.confirm(
      "Blocking this conversation will hide it from both you and the other party.\n\nAre you sure?"
    );

    if (!confirmBlock) {
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/close-chat`,
        {
          idchats: chat.idchats,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        alert(
          "Conversation closed successfully. You and the other party will no longer see this conversation."
        );
        setChats((prevChats) =>
          prevChats.filter((c) => c.idchats !== chat.idchats)
        );
        closeChatButton();
        // Tässä voit halutessasi tehdä muita toimenpiteitä, kuten päivittää tilaa
      } else {
        alert("Failed to close conversation");
      }
    } catch (error) {
      console.error("Error closing conversation:", error);
      alert("Error closing conversation");
    }
  };

  return (
    <div>
      <Helmet>
        <title>Messages - Pirpana</title>
        <meta
          name="description"
          content="View your messages on Pirpana. Check your inbox, manage conversations, and stay updated with notifications."
        />
        <meta
          name="keywords"
          content="messages, inbox, conversations, Pirpana, mail, new message"
        />
      </Helmet>
      {warningBoxVisible && (
        <div
          className="warning-box-container position-relative mt-2 p-2 w-100"
          style={{
            backgroundColor: "#fffacd",
            borderRadius: "5px",
            border: "1px solid #f0c36d",
          }}
        >
          <div
            className="warning-text tsm"
            style={{
              paddingRight: "20px",
              lineHeight: "0.9",
            }}
          >
            Please do not click on links that lead outside of Pirpana, and
            always check the sender's email address. If it doesn't end with
            "@pirpana.com," it is not from Pirpana, and we recommend deleting
            the message immediately.
          </div>
          <button
            onClick={handleCloseWarningBox}
            className="close-button"
            style={{
              position: "absolute",
              top: "-12px",
              right: "0px",
              backgroundColor: "transparent",
              border: "none",
              fontSize: "30px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
        </div>
      )}
      <div className="d-flex justify-content-center align-items-center">
        <div className="new-item-container m-2 mt-3 p-2 w-100">
          <div>
            {!selectedChat ? (
              <div>
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ marginBottom: 20, marginTop: 0 }}
                >
                  <h2 className="title m-3">My Messages</h2>
                  <img
                    src={chattingfigures}
                    loading="lazy"
                    alt="Background"
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: "50%",
                      objectFit: "cover",
                      marginRight: 10,
                    }}
                  />
                </div>
                <ul className="list-group" style={{ fontSize: "0.9rem" }}>
                  {chats.map((chat) => (
                    <li
                      key={chat.idchats}
                      className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                      onClick={() => handleChatClick(chat)}
                    >
                      <p
                        className="m-0"
                        //style={{ fontFamily: "'Roboto Condensed', sans-serif" }}
                      >
                        {chat.title}
                      </p>
                      {notifIds.includes(chat.idchats) && (
                        <img
                          src={messagesnew}
                          loading="lazy"
                          alt="unread"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginLeft: "8px",
                          }}
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div>
                {chatItem && (
                  <div className="">
                    <div className="d-flex justify-content-between">
                      <div className="w-100">
                        <div>
                          <div
                            className="d-flex justify-content-between fixed-top card-bg p-2 font-resize-open-chat-title"
                            style={{ height: "56px", overflow: "hidden" }}
                          >
                            <div className="d-flex justify-content-between">
                              <p>
                                <strong className="narrow font-resize-open-chat-title">
                                  {chatItem.itemtitle}
                                </strong>{" "}
                                (id
                                {chatItem.iditems}){" "}
                                <strong>{chatItem.price}</strong>PHP
                              </p>
                            </div>
                            <img
                              src={closeButton}
                              loading="lazy"
                              alt="Close"
                              onClick={() => closeChatButton()}
                              style={{
                                cursor: "pointer",
                                width: "40px",
                                height: "40px",
                                marginRight: 0,
                                marginLeft: 6,
                              }}
                            />
                          </div>
                          <strong>Description:</strong>{" "}
                          <p className="">{chatItem.itemdescription}</p>
                          <p className="card-text mt-1">
                            <strong>Category:</strong>{" "}
                            {chatItem.subcategoryname}
                          </p>
                          {chatItem.itemcondition && (
                            <p className="card-text narrow">
                              <strong>Condition:</strong>{" "}
                              {chatItem.itemcondition}
                            </p>
                          )}
                          <p className="card-text mb-1">
                            {chatItem.sellbuy === "sell" ? (
                              <>
                                User{" "}
                                <b style={{ color: "rgb(0, 139, 139)" }}>
                                  {chatItem.holder_name}
                                </b>{" "}
                                is selling this item
                              </>
                            ) : (
                              <>
                                User{" "}
                                <b style={{ color: "rgb(0, 139, 139)" }}>
                                  {chatItem.holder_name}
                                </b>{" "}
                                is looking to buy this item
                              </>
                            )}
                          </p>
                          <div className="mb-2">
                            <a
                              href={`/useritems/${chatItem.holder_idusers}`}
                              style={{
                                color: "black",
                                padding: "2px 10px",
                                borderRadius: "8px",
                                border: "1px solid gray",
                                textDecoration: "none",
                              }}
                            >
                              See this user's other items 🗒️
                            </a>
                          </div>
                          <p className="card-text narrow">
                            <strong className="">Added:</strong>{" "}
                            {formatDate(chatItem.dateadded)}
                          </p>
                          <p className="card-text">
                            <strong className="">Last edited:</strong>{" "}
                            {formatDate(chatItem.lastedited)}
                          </p>
                          <div className="card-text mb-3">
                            <img
                              src={locationpin}
                              loading="lazy"
                              alt="Location"
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: 2,
                                marginLeft: 2,
                                marginTop: -6,
                              }}
                            />
                            {chatItem.location_info}
                          </div>
                          <button
                            type="submit"
                            className="btn-submit p-1 mb-2"
                            onClick={() =>
                              handleBlockConversation(selectedChat)
                            }
                            style={{
                              backgroundColor: "rgb(163, 163, 163)",
                              border: 0,
                              display: "block",
                              fontSize: "0.8rem",
                              padding: "5px 10px",
                              borderRadius: "4px",
                              lineHeight: "0.8",
                            }}
                          >
                            ⚠️ Block Conversation
                          </button>
                          {chatItem.imagePaths.length > 0 && (
                            <div className="image-gallery">
                              {chatItem.imagePaths.map((imagePath, index) => (
                                <img
                                  key={index}
                                  src={`${process.env.REACT_APP_SERVER}/server/${imagePath}`}
                                  loading="lazy"
                                  alt={`Item ${index}`}
                                  style={{
                                    width: "100%",
                                    objectFit: "cover",
                                    marginBottom: 5,
                                  }}
                                />
                              ))}
                            </div>
                          )}
                          <div
                            className="bg-white mt-3"
                            style={{ width: "100%", height: "6px" }}
                          ></div>
                          <div
                            className="fixed-bottom"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {/* Elementti, joka tulee näkyviin, kun isTypeMessageVisible on true */}
                            {isTypeMessageVisible && (
                              <div
                                className="fixed-bottom turquoise-button p-2 border turquoise-button"
                                style={{
                                  position: "fixed",
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  top: 56,
                                  width: "100%",
                                  height: "auto",
                                  display: "flex",
                                  flexDirection: "column", // Asettaa sisällön pinoon
                                }}
                              >
                                <div>
                                  <button
                                    className="btn-submit"
                                    onClick={() =>
                                      setTypeMessageIsVisible(false)
                                    }
                                    style={{
                                      width: 40,
                                      height: 40,
                                      marginRight: 5,
                                      marginTop: "-22px",
                                      marginBottom: 20,
                                      padding: 0,
                                      cursor: "pointer",
                                      fontSize: "40px",
                                      border: "none",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <span
                                    className="p-1"
                                    style={{ fontSize: 20 }}
                                  >
                                    New message
                                  </span>
                                </div>
                                <textarea
                                  className="form-control p-2"
                                  placeholder="message..."
                                  value={inputMessage}
                                  onChange={handleMessageChange}
                                  rows={3}
                                  style={{
                                    flex: 1,
                                    resize: "none",
                                  }} // Estää käyttäjää muuttamasta textarea:n kokoa
                                />
                                <button
                                  className="btn-submit turquoise-button border"
                                  onClick={() =>
                                    handleSendMessage(selectedChat)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    borderRadius: "12px",
                                    width: "100%",
                                    height: 80,
                                    padding: 10,
                                    marginTop: 5,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <h6
                                    style={{
                                      marginRight: "10px",
                                      textAlign: "right",
                                    }}
                                  >
                                    Send a message about
                                    <br />"{chatItem.itemtitle}"
                                  </h6>
                                  <img
                                    src={send}
                                    loading="lazy"
                                    alt="Send"
                                    style={{
                                      width: 60,
                                      height: 60,
                                      borderRadius: "12px",
                                    }}
                                  />
                                </button>
                                <div
                                  className="warning-box-container position-relative mt-2 p-2 w-100"
                                  style={{
                                    backgroundColor: "#fffacd",
                                    borderRadius: "5px",
                                    border: "1px solid #f0c36d",
                                  }}
                                >
                                  <div
                                    className="warning-text tsm"
                                    style={{
                                      lineHeight: "0.9",
                                    }}
                                  >
                                    Do not share personal details such as your
                                    address, phone number, email, or social
                                    media accounts. All transactions should be
                                    conducted exclusively through the official
                                    Pirpana website. In cases of misuse,
                                    conversations are accessible to authorities.
                                    Ensure you are on our secure site to protect
                                    your privacy.
                                  </div>
                                </div>
                                <img
                                  src={chattingpeople}
                                  loading="lazy"
                                  alt="people"
                                  style={{
                                    width: "100%",
                                    height: "40%",
                                    padding: 5,
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <ul className="p-3">
                  {selectedChat.messages && selectedChat.messages.length > 0 ? (
                    selectedChat.messages.map((message, index) => (
                      <li
                        className="bg-white"
                        key={index}
                        style={{
                          padding: 20,
                          textAlign:
                            message.username === MyUserName ? "right" : "left",
                          margin:
                            message.username === MyUserName
                              ? "0 0 30px 40px"
                              : "0 40px 30px 0", // Lisää tilaa viestien väliin
                        }}
                      >
                        {message.content}
                        <p
                          className="bg-white m-0"
                          style={{
                            fontSize: "0.75rem",
                            color:
                              message.username === MyUserName
                                ? "rgb(0, 76, 153)"
                                : "rgb(0, 139, 139)",
                          }}
                        >
                          {formatDate(message.timestamp)} / {message.username}
                        </p>
                      </li>
                    ))
                  ) : (
                    <li>No messages</li>
                  )}
                </ul>
                {/* Nappi, joka näyttää elementin */}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    onClick={() => setTypeMessageIsVisible(true)}
                    className="btn-submit turquoise-button"
                    style={{
                      cursor: "pointer",
                      borderRadius: "50px",
                      width: 80,
                      height: 80,
                      margin: 5,
                      marginBottom: 40,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    <img
                      src={newmessagebutton}
                      loading="lazy"
                      alt="new message"
                      style={{
                        width: 80,
                        height: 80,
                        fontSize: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none",
                      }}
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div ref={messagesEndRef} />
      {!selectedChat ? (
        <div className="container d-flex justify-content-center align-items-center">
          <img
            src={mailN}
            loading="lazy"
            alt="mail"
            className="border"
            style={{
              width: "100%",
              maxWidth: "600px",
              height: 360,
              objectFit: "cover",
              marginBottom: 10,
              marginTop: 10,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Messages;
